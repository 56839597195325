<template>
<v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>Caseta</v-card-title>
        <v-card-text>
            <v-row>
            <v-col cols="6">
                <v-text-field
                                v-model="caseta.caseta"
                                label="Nombre de la Caseta"
                                class="uppercase"
                                
                            /> 
            </v-col>
          
            <v-col cols="2">
                <v-text-field
                                label="Km"
                                class="uppercase"
                                v-model="caseta.km"

                              
                              
                            />
            </v-col>
                       

            </v-row>
            <v-row>
                         <v-col cols="2">
                <v-text-field
                                label="$ Tres Ejes"
                                class="uppercase"
                                v-model="caseta.tres_ejes"

                              
                              
                            />
            </v-col>


                         <v-col cols="2">
                <v-text-field
                                label="$ Cinco Ejes"
                                class="uppercase"
                                v-model="caseta.cinco_ejes"

                              
                              
                            />
            </v-col>
        
                         <v-col cols="2">
                <v-text-field
                                label="$ Nueve Ejes"
                                class="uppercase"
                                v-model="caseta.nueve_ejes"

                              
                              
                            />
            </v-col>
            
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-btn color="success" @click="savecaseta">
                     <v-icon>mdi-content-save-outline</v-icon>
                  Guardar
                </v-btn>
              </v-col>
              </v-row>
        </v-card-text>
      </base-card>
    </v-col>

</v-row>
</template>
<script>
import { url } from "../../main.js";
import moment from "moment-timezone";

export default {

   metaInfo: {
      // title will be injected into parent titleTemplate
      title: "Caseta"
    },
    data () {
      return {
                     showScheduleForm: false,

          enviar: {},
        idcaseta: this.$route.params.idcaseta,
          edit:false,
              caseta: {
        idcaseta: this.$route.params.idcaseta,
        km: "",
        tres_ejes: "",
        cinco_ejes: "",
        nueve_ejes: "",
      
       
      }
            }
    },
          mounted(){
this.permiso = localStorage.getItem("permission");
    if(this.idcaseta==0)
    {


    }else{
    this.editcaseta();
   
    }
    },
    methods:{
        editcaseta() {
      this.axios
        .get(url + "apiVias.php/caseta/" + this.idcaseta)
        .then((respuesta) => {
          console.log(respuesta);
          this.caseta = respuesta.data;
          this.edit = true;
        });
    }, 
  
      
        savecaseta() {
            console.log(this.validarcampos())
      if (this.validarcampos()) {
        if (this.caseta != null) {
          console.log(this.caseta);
          this.axios
            .post(url + "apiVias.php/casetas", this.caseta)
            .then((respuesta) => {
              console.log(respuesta);
              if (respuesta.status==200) {
                console.log(this.caseta);
                if(this.caseta.idcaseta==0)
                {
                this.$swal(
                
          "Caseta agregada",
            "",
          "success"
        );
                        this.limpiarcampos();

                }else{
                  this.$swal(
                
          "Caseta actualizada",
            "",
          "success"
        );
                }
               
                
              }
            })
            .catch(function () {
              console.log("FAILURE!!");
            });
        }
      } else {
        this.$swal(
          "Debe ingresar todos los datos de la caseta",
          "warning"
        );
      }
    },
    selectlasttipo(){
      console.log('xxx');
  var size=this.lista_tipos.length;
          this.caseta.idtipo_caseta = this.lista_tipos[size-1].idtipo_caseta;
    },
    limpiarcampos() {
      this.caseta = {};
        
        this.idcaseta= this.$route.params.idcaseta
        this.km= ""
        this.tres_ejes= ""
        this.cinco_ejes= ""
        this.nueve_ejes= ""
      
       
      

    },
    
   
    validarcampos() {
        console.log(this.caseta);
      if (
        this.caseta.caseta == ""
        //||
        // this.caseta.telefono == ""
      ) {
        return false;
      } else {
        return true;
      }
    },
    
   
   
    }
      
    }
</script>
